import IconCross from 'assets/images/_icons/iconCross.png';
import { Section } from 'components/_layout/Section/Section';
import * as Common from 'components/_universal/Common';
import { graphql, StaticQuery } from 'gatsby';
import { useTheme } from 'layout/ThemeContext';
import React from 'react';
import { TagTile } from 'sections/Blog/BlogItem/BlogItem.styled';
import shortId from 'shortid';
import {
    CategoriesTileWrapper,
    Tile,
    TileTitle,
} from 'src/sections/Blog/Categories/Categories.styled';
import { B4 } from 'styles/Typography.styled';

export default (props: any) => (
    <StaticQuery
        query={graphql`
            query {
                allWordpressCategory {
                    edges {
                        node {
                            slug
                            name
                        }
                    }
                }
            }
        `}
        render={(data) => <Categories data={data} {...props} />}
    />
);

const Categories = ({ data }: any) => {
    const {
        activeCategory,
        setActiveCategory,
        setBlogTagsSelected,
        blogTagsSelected,
    } = useTheme();

    const handleTagRemove = (tagName: string) => {
        setBlogTagsSelected(blogTagsSelected.filter((b) => b !== tagName));
    };

    const handleCategoryClick = (category: string) => {
        setBlogTagsSelected([]);
        setActiveCategory(category);
    };

    return (
        <Section title="Blog" isFirst>
            <CategoriesTileWrapper>
                <B4 textWrap="nowrap">Search categories: </B4>
                <Common.Div flex="row" flexWrap="wrap" gap="8px">
                    <Tile
                        isActive={activeCategory === 'all'}
                        onClick={() => handleCategoryClick('all')}
                    >
                        <TileTitle>ALL</TileTitle>
                    </Tile>
                    {data.allWordpressCategory.edges.map(({ node }: any) => (
                        <>
                            <Tile
                                key={shortId.generate()}
                                isActive={activeCategory === node.slug}
                                onClick={() => handleCategoryClick(node.slug)}
                                displayNone={node.slug === 'uncategorised'}
                            >
                                <TileTitle>{node.name}</TileTitle>
                            </Tile>
                        </>
                    ))}
                </Common.Div>
            </CategoriesTileWrapper>
            {blogTagsSelected.length > 0 && <B4 mb={2}>Tags: </B4>}
            <Common.Div flex="row" gap="8px" flexWrap="wrap">
                {blogTagsSelected.map((b) => (
                    <TagTile key={b} onClick={() => handleTagRemove(b)}>
                        {b}
                        <Common.Svg src={IconCross} w="10px" h="10px" />
                    </TagTile>
                ))}
            </Common.Div>
        </Section>
    );
};
