import { Section } from 'components/_layout/Section/Section';
import * as Common from 'components/_universal/Common';
import { graphql, StaticQuery } from 'gatsby';
import { useTheme } from 'layout/ThemeContext';
import React from 'react';
import { BorderSeparator } from 'sections/Blog/BlogItem/BlogItem.styled';
import { MaxWidthWrapper } from 'sections/Blog/PostListing/PostListing.styled';
import shortId from 'shortid';
import { BlogItem } from 'src/sections/Blog/BlogItem/BlogItem';
import { B3, H1 } from 'styles/Typography.styled';

export default (props: any) => (
    <StaticQuery
        query={graphql`
            query {
                allWordpressPost(
                    # limit: 6
                    sort: { fields: [date], order: DESC }
                ) {
                    edges {
                        node {
                            date(formatString: "MMMM DD, YYYY")
                            wordpress_id
                            id
                            title
                            excerpt
                            slug
                            acf {
                                image {
                                    source_url
                                }
                                reading_length
                            }
                            author {
                                id
                                name
                                description
                                avatar_urls {
                                    wordpress_48
                                }
                            }
                            categories {
                                id
                                name
                                slug
                            }
                            tags {
                                id
                                name
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => <PostListing data={data} {...props} />}
    />
);

const PostListing = ({ data }: any) => {
    const { activeCategory, blogTagsSelected } = useTheme();

    const filteredByCategory = data?.allWordpressPost?.edges.filter(
        (edge: any) => {
            return activeCategory === 'all'
                ? edge?.node
                : edge?.node?.categories?.some((category: any) =>
                      category.slug.toLowerCase().includes(activeCategory),
                  );
        },
    );

    const filteredData =
        blogTagsSelected.length !== 0
            ? filteredByCategory.filter((edge: any) =>
                  blogTagsSelected.every((tagSelected) =>
                      edge?.node?.tags.map((t) => t.name).includes(tagSelected),
                  ),
              )
            : filteredByCategory;

    return (
        <Section variant="secondary">
            {activeCategory === 'all' && blogTagsSelected.length === 0 ? (
                data.allWordpressPost.edges.map(
                    ({ node }: any, index: number) => {
                        return (
                            <>
                                <BlogItem
                                    key={shortId.generate()}
                                    data={node}
                                />
                                {index !==
                                    data.allWordpressPost.edges.length - 1 && (
                                    <BorderSeparator />
                                )}
                            </>
                        );
                    },
                )
            ) : filteredData.length === 0 ? (
                <Common.Div flex={'column'} alignItems={'center'} gap="32px">
                    <MaxWidthWrapper>
                        <H1>No results found</H1>
                        <B3>
                            We don't have articles in this category yet, the
                            first one will be published soon. Take a look at
                            other categories.
                        </B3>
                    </MaxWidthWrapper>
                </Common.Div>
            ) : (
                filteredData.map(({ node }: any, index: number) => {
                    return (
                        <>
                            <BlogItem key={shortId.generate()} data={node} />
                            {index !== filteredData.length - 1 && (
                                <BorderSeparator />
                            )}
                        </>
                    );
                })
            )}
        </Section>
    );
};
