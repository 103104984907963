import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { H1, SectionWrapper } from 'styles/Typography.styled';

export const ContactWrapper = styled.div`
    padding: 128px 20px 100px 20px;

    ${mediaQueries.xs} {
        padding: 150px 0 50px 0;
    }
`;

export const ContentWrapper = styled(SectionWrapper)`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const SectionTitle = styled(H1)(
    () =>
        css`
            margin-bottom: 32px;
        `,
);

export const CategoriesTileWrapper = styled.div(
    () =>
        css`
            margin-top: 62px;
            margin-bottom: 32px;
            display: flex;
            gap: 16px;
            width: 100%;
            flex-direction: column;
            flex-wrap: wrap;
        `,
);

export const Tile = styled.div<{ isActive?: boolean; displayNone?: boolean }>(
    ({
        displayNone,
        isActive,
        theme: { colors, fontSizes, fontFamily },
    }) => css`
        align-items: center;
        background: ${colors.buttons};
        color: ${isActive ? colors.boatBlue : colors.text};
        font-weight: ${isActive ? 800 : 500};
        cursor: ${isActive ? 'default' : 'pointer'};
        display: ${displayNone ? 'none' : 'flex'};
        font-size: ${fontSizes.f12};
        justify-content: space-between;
        line-height: 1;
        padding: 12px 32px;
        text-transform: lowercase;
        width: fit-content;
        gap: 8px;

        ${mediaQueries.xs} {
            justify-content: space-between;
            width: fit-content;
        }
    `,
);

export const TileTitle = styled.p`
    width: 100%;
    text-align: center;
`;
