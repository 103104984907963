import * as Common from 'components/_universal/Common';
import { navigate } from 'gatsby';
import { useTheme } from 'layout/ThemeContext';
import React from 'react';
import { ROUTE_BLOG } from 'shared/paths';
import {
    BlogItemExcerpt,
    BlogItemImage,
    BlogItemReadingLength,
    BlogItemTitle,
    InfoWrapper,
    PointerSpan,
    TagTile,
    Wrapper,
} from 'src/sections/Blog/BlogItem/BlogItem.styled';
import he from 'he';

export interface Props {
    data: any;
}

export const BlogItem = ({ data }: Props) => {
    const { setBlogTagsSelected, blogTagsSelected, setActiveCategory } =
        useTheme();

    const handleTagClick = (e: Event, tag: string) => {
        e.stopPropagation();
        if (!blogTagsSelected.includes(tag)) {
            setActiveCategory('all');
            setBlogTagsSelected([...blogTagsSelected, tag]);
            scrollTo({
                behavior: 'smooth',
                top: 0,
            });
        }
    };

    const handlePostClick = (e: Event, slug: string) => {
        e.preventDefault();
        navigate(`${ROUTE_BLOG}/${slug}`);
    };

    return (
        <PointerSpan onClick={(e) => handlePostClick(e, data.slug)}>
            <Wrapper>
                <InfoWrapper>
                    <BlogItemTitle>{he.decode(data.title)}</BlogItemTitle>
                    <Common.Div flex="row" gap="8px" mb={2} flexWrap="wrap">
                        {data.tags.map(({ name }: any) => (
                            <TagTile
                                onClick={(e) => handleTagClick(e, name)}
                                key={name}
                            >
                                {' #' + name}
                            </TagTile>
                        ))}
                    </Common.Div>
                    <BlogItemExcerpt
                        dangerouslySetInnerHTML={{ __html: data.excerpt }}
                    />
                    {data.acf.reading_length && (
                        <BlogItemReadingLength>
                            {data.acf.reading_length} min read
                        </BlogItemReadingLength>
                    )}
                </InfoWrapper>
                <BlogItemImage src={data.acf.image.source_url} />
            </Wrapper>
        </PointerSpan>
    );
};
