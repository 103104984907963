import { CommonMargin } from 'components/_layout/CommonMargin';
import Categories from 'components/../sections/Blog/Categories/Categories';
import PostListing from 'components/../sections/Blog/PostListing/PostListing';
import React from 'react';

export default () => {
    return (
        <CommonMargin>
            <Categories />
            <PostListing />
        </CommonMargin>
    );
};
